export const isPermitted = (permissions, allowed) => {
  let perm = false;
  allowed?.forEach((element) => {
    if (permissions?.includes(element)) {
      perm = true;
    }
  });

  return perm;
};
