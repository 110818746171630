export const siteInfo = {
  name: "Skybuy BD",
  navColor: "#00337C",
  secondary: "#41BCE1",
  primary_light_bg: "#E9EFF0",
  asideColor: "#fff",
  payable: 0.5,
  minimum_price: 1000,
  minimum_quantity: 3,
  error: "#C4161C",
  tableHeader: "#E9F0EE",
  by_air: {
    a: 630,
    b: 750,
  },
};
