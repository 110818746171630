import "./App.css";
import React, { Suspense, lazy, useState } from "react";
import { ConfigProvider } from "antd";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { store } from "./store";

import { Provider } from "react-redux";
import setAuthToken from "./utils/setAuthToken";
import AdminRoute from "./routes/AdminRoute";
import Sidebar from "./layout/Sidebar";
import Navbar from "./layout/Navbar";
import { saveConfig, setAdmin } from "./actions/AuthAction";
import { setConfig } from "./reducers/configReducer";

import { setProfile } from "./reducers/authReducer";
import Bottombar from "./layout/BottomBar";

import { siteInfo } from "./utils/SiteInfo";

const Confirmation = lazy(() => import("./pages/PaymentConfirmation"));
const Commission = lazy(() => import("./pages/Commission"));
const Withdraw = lazy(() => import("./pages/Withdraw"));
const Pay = lazy(() => import("./pages/Pay"));

const Coupon = lazy(() => import("./pages/Coupons"));

const Customers = lazy(() => import("./pages/Customers"));
const Login = lazy(() => import("./pages/Login"));
const Orders = lazy(() => import("./pages/Orders"));
const ManageOrder = lazy(() => import("./pages/ManageOrder"));
const Admins = lazy(() => import("./pages/Admins"));
const Payments = lazy(() => import("./pages/Payments"));
const Profile = lazy(() => import("./pages/Profile"));
const ComingSoon = lazy(() => import("./pages/ComingSoon"));
const Purchasing = lazy(() => import("./pages/Purchasing"));

if (localStorage.config) {
  let config = JSON.parse(localStorage.config);
  let profile = config.profile;
  store.dispatch(setProfile(profile));
  store.dispatch(setConfig(config));
}

if (localStorage.skyagent) {
  setAuthToken(localStorage.skyagent);
  store.dispatch(setAdmin(true));
  store.dispatch(saveConfig());
}

const Dashboard = lazy(() => import("./pages/Dashboard"));

function App() {
  const client = window.screen.width > 768 ? "web" : "app";
  const [show, setshow] = useState(client === "web" ? false : true);
  const [trigger, settrigger] = useState(false);

  const id = store.getState().auth?.profile?._id;

  const host = window.location.host;

  if (host !== "agent.skybuybd.com" && host !== "localhost:4000") {
    return (
      <div
        className="flex align-center justify-center flexColumn"
        style={{ height: "100vh", width: "100%", background: "#fff" }}
      >
        <img src={require("./assets/locked.png")} style={{ width: 220 }} />
        <h2 className="mt1">Site Locked</h2>
        <p>This website is locked please contact authorized person.</p>
      </div>
    );
  }

  const roles = store.getState().auth?.profile?.roles
    ? store.getState().auth?.profile?.roles
    : [];

  return (
    <Provider store={store}>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: siteInfo.navColor,
            borderRadius: 2,
          },
        }}
      >
        <BrowserRouter>
          <Suspense fallback={<div className="pageProgress"></div>}>
            <div className="App">
              <Sidebar state={{ show, setshow }} />
              <div>
                <Navbar state={{ settrigger, setshow, show }} />
                {id && <Bottombar />}
                <Routes>
                  <Route
                    exact
                    path="/"
                    element={
                      <AdminRoute
                        state={{ show, trigger }}
                        component={<Dashboard />}
                      />
                    }
                  />

                  <Route
                    exact
                    path="/customers"
                    element={
                      <AdminRoute
                        state={{ show, trigger }}
                        component={<Customers />}
                      />
                    }
                  />

                  <Route
                    exact
                    path="/commission"
                    element={
                      <AdminRoute
                        state={{ show, trigger }}
                        component={<Commission />}
                      />
                    }
                  />
                  <Route
                    exact
                    path="/withdraw"
                    element={
                      <AdminRoute
                        state={{ show, trigger }}
                        component={<Withdraw />}
                      />
                    }
                  />

                  <Route
                    exact
                    path="/pay/:key"
                    element={
                      <AdminRoute
                        state={{ show, trigger }}
                        component={<Pay />}
                      />
                    }
                  />
                  <Route
                    exact
                    path="/payment/confirmation/:key"
                    element={
                      <AdminRoute
                        state={{ show, trigger }}
                        component={<Confirmation />}
                      />
                    }
                  />

                  <Route
                    exact
                    path="/orders"
                    element={
                      <AdminRoute
                        state={{ show, trigger }}
                        component={<Orders />}
                      />
                    }
                  />
                  <Route
                    exact
                    path="/order/:id"
                    element={
                      <AdminRoute
                        state={{ show, trigger }}
                        component={<ManageOrder />}
                      />
                    }
                  />
                  <Route
                    exact
                    path="/coupons"
                    element={
                      <AdminRoute
                        state={{ show, trigger }}
                        component={<Coupon />}
                      />
                    }
                  />

                  <Route
                    exact
                    path="/payments"
                    element={
                      <AdminRoute
                        state={{ show, trigger }}
                        component={<Payments />}
                      />
                    }
                  />

                  <Route
                    exact
                    path="/profile"
                    element={
                      <AdminRoute
                        state={{ show, trigger }}
                        component={<Profile />}
                      />
                    }
                  />

                  <Route exact path="/login" element={<Login />} />
                  <Route
                    path="*"
                    element={
                      <AdminRoute
                        state={{ show, trigger }}
                        component={
                          <div
                            style={{
                              height: "calc(100vh - 78px)",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              backgroundColor: "#fff",
                            }}
                          >
                            <ComingSoon />
                          </div>
                        }
                      />
                    }
                  />
                </Routes>
              </div>
            </div>
          </Suspense>
        </BrowserRouter>
      </ConfigProvider>
    </Provider>
  );
}

export default App;
