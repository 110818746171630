import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  config: null,
};

export const configSlice = createSlice({
  name: "counter",
  initialState,
  reducers: {
    setConfig: (state, action) => {
      state.config = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setConfig } = configSlice.actions;

export default configSlice.reducer;
