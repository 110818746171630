import {
  AuditOutlined,
  BarChartOutlined,
  CalendarOutlined,
  CarOutlined,
  CloudServerOutlined,
  DesktopOutlined,
  DollarOutlined,
  GiftOutlined,
  GoldOutlined,
  HomeOutlined,
  LogoutOutlined,
  PictureOutlined,
  SelectOutlined,
  SettingOutlined,
  TeamOutlined,
  UsergroupAddOutlined,
  UserOutlined,
  BarcodeOutlined,
  BuildOutlined,
  SlidersOutlined,
  OneToOneOutlined,
  EuroCircleOutlined,
  AppstoreOutlined,
  NotificationOutlined,
  PhoneOutlined,
  MessageOutlined,
  PropertySafetyOutlined,
  PercentageOutlined,
} from "@ant-design/icons";
import {
  IoChatboxOutline,
  IoHeartOutline,
  IoBagHandleOutline,
  IoCartOutline,
  IoHomeOutline,
  IoGitBranchSharp,
  IoBarChartSharp,
  IoCopyOutline,
  IoMenuOutline,
  IoBarChartOutline,
  IoBagCheckOutline,
  IoPeopleOutline,
  IoReorderFourOutline,
  IoGiftOutline,
} from "react-icons/io5";
import {
  BsCardChecklist,
  BsCartCheck,
  BsCashCoin,
  BsGift,
  BsHouseDoor,
  BsPeople,
  BsPercent,
  BsPerson,
  BsReplyAll,
} from "react-icons/bs";
import { Layout, Menu, notification } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { IoCashOutline } from "react-icons/io5";
import SubMenu from "antd/es/menu/SubMenu";
import { logoutAdmin } from "../actions/AuthAction";
import { isPermitted } from "../utils/PermissionManager";
//   import { sidebarItems } from "../utils/Sidebars";
const { Sider } = Layout;

export default function SideMenu(props) {
  const dispatch = useDispatch();
  const location = useLocation();
  const path = location.pathname;
  const roles = useSelector((state) => state.auth.profile?.roles);
  const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
      message: message,
      description: description,
    });
  };

  const menuStyle = {
    fontSize: "22px",
    width: "18px",
  };

  return (
    <Menu
      theme="light"
      mode="inline"
      defaultSelectedKeys={[path.toString()]}
      defaultOpenKeys={["accounts"]}
    >
      <Menu.Item key={"/"} icon={<BsHouseDoor style={menuStyle} />}>
        <Link to={"/"}>Dashboard</Link>
      </Menu.Item>
      <Menu.Item key={"/customers"} icon={<BsPeople style={menuStyle} />}>
        <Link to={"/customers"}>Customers</Link>
      </Menu.Item>
      <Menu.Item key={"/orders"} icon={<BsCartCheck style={menuStyle} />}>
        <Link to={"/orders"}>Orders</Link>
      </Menu.Item>

      <Menu.Item key={"/coupons"} icon={<BsGift style={menuStyle} />}>
        <Link to={"/coupons"}>Coupons</Link>
      </Menu.Item>

      <Menu.Item key={"/payments"} icon={<BsCashCoin style={menuStyle} />}>
        <Link to={"/payments"}>Payments</Link>
      </Menu.Item>
      <Menu.Item key={"/commission"} icon={<BsPercent style={menuStyle} />}>
        <Link to={"/commission"}>Commission</Link>
      </Menu.Item>
      <Menu.Item key={"/withdraw"} icon={<BsCardChecklist style={menuStyle} />}>
        <Link to={"/withdraw"}>Withdraw</Link>
      </Menu.Item>

      {/* {isPermitted(roles, ["super_admin", "admin"]) && (
        <SubMenu key="reports" icon={<BarChartOutlined />} title="Reports">
          {isPermitted(roles, ["super_admin", "admin"]) && (
            <Menu.Item
              key={"/monthly-report"}
              icon={<CalendarOutlined style={menuStyle} />}
            >
              <Link to={"/monthly-report"}>Monthly Report</Link>
            </Menu.Item>
          )}
          {isPermitted(roles, ["super_admin"]) && (
            <Menu.Item
              key={"/profit-report"}
              icon={<DollarOutlined style={menuStyle} />}
            >
              <Link to={"/profit-report"}>Profit Report</Link>
            </Menu.Item>
          )}
          {isPermitted(roles, ["admin"]) && (
            <Menu.Item
              key={"/sms-report"}
              icon={<MessageOutlined style={menuStyle} />}
            >
              <Link to={"/sms-report"}>Sms Report</Link>
            </Menu.Item>
          )}
        </SubMenu>
      )} */}
      {/* <SubMenu key="settings" icon={<SettingOutlined />} title="Settings">
        <Menu.Item
          key={"/settings/bank"}
          icon={<EuroCircleOutlined style={menuStyle} />}
        >
          <Link to={"/settings/bank"}>Bank</Link>
        </Menu.Item>
      </SubMenu> */}

      <Menu.Item key="/SettingOutlined " icon={<BsPerson style={menuStyle} />}>
        <Link to={"/profile"}>Profile</Link>
      </Menu.Item>
      <Menu.Item
        key="/logout"
        onClick={() => {
          dispatch(logoutAdmin(() => {}));
          openNotificationWithIcon(
            "success",
            "Success",
            "Logged out successfully"
          );
        }}
        icon={<BsReplyAll style={menuStyle} />}
      >
        Logout
      </Menu.Item>
    </Menu>
  );
}
